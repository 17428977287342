<template>
  <div>
    <core-page-title />

    <v-container class="mt-3">
      <v-row>
        <v-col>
          <v-btn
            color="success"
            class="mr-2"
            dark
            @click="openDialog()"
          >
            <v-icon
              left
              v-text="'mdi-plus'"
            />
            Adicionar
          </v-btn>
        </v-col>
      </v-row>

      <v-row class="mt-4">
        <v-col>
          <v-sheet elevation="1">
            <v-data-table
              disable-filtering
              disable-sort
              :server-items-length="count"
              :loading="loading"
              :headers="headers"
              :items="users"
              :options.sync="pagination"
              :footer-props="{
                'items-per-page-options': [10, 20, 50]
              }"
              @update:options="loadUsers"
            >
              <template v-slot:[`item.active`]="{ item }">
                {{ item.active ? 'Sim' : 'Não' }}
              </template>
              <template
                v-slot:[`item.actions`]="{ item }"
                class="text-right"
              >
                <v-btn
                  icon
                  small
                  @click="openDialogChangePassword(item)"
                >
                  <v-icon
                    v-text="'mdi-lock-reset'"
                    small
                  />
                </v-btn>
                <v-btn
                  icon
                  small
                  @click="openDialog(item)"
                >
                  <v-icon
                    v-text="'mdi-pencil'"
                    small
                  />
                </v-btn>
                <v-btn
                  icon
                  small
                >
                  <v-icon
                    v-text="'mdi-delete'"
                    small
                    @click="clickDeleteItem(item)"
                  />
                </v-btn>
              </template>
            </v-data-table>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>

    <dialog-user
      v-model="showDialogUser"
      :user-id="editId"
      @save="loadUsers"
    />

     <dialog-user-change-password
      v-model="showDialogChangePassword"
      :user-id="changePasswordId"
    />

    <dialog-delete
      v-model="showDialogDelete"
      :loading="loadingDelete"
      @confirm="deleteItem"
    />

  </div>
</template>

<script>
  import usersApi from '@/api/users'

  export default {
    components: {
      DialogDelete: () => import('@/components/admin/dialog/DialogDelete'),
      DialogUser: () => import('@/components/admin/dialog/users/DialogUser'),
      DialogUserChangePassword: () => import('@/components/admin/dialog/users/DialogUserChangePassword')
    },

    data () {
      return {
        loading: false,
        loadingDelete: false,
        showDialogUser: false,
        showDialogChangePassword: false,
        showDialogDelete: false,
        count: 0,
        deleteId: null,
        editId: null,
        changePasswordId: null,
        headers: [
          { text: '#', value: 'id' },
          { text: 'Nome', value: 'name' },
          { text: 'Usuário', value: 'username' },
          { text: 'Ativo', value: 'active' },
          { text: '', value: 'actions', align: 'right' },
        ],
        users: [],
        pagination: {},
      }
    },

    methods: {
      async loadUsers () {
        try {
          this.loading = true

          const response = await usersApi.list({
            limit: this.pagination.itemsPerPage,
            offset: (this.pagination.page * this.pagination.itemsPerPage) - this.pagination.itemsPerPage,
          })

          this.users = response.data.users
          this.count = response.data.count
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loading = false
        }
      },

      openDialog (user = null) {
        this.editId = user ? user.id : null
        this.showDialogUser = true
      },

      openDialogChangePassword (user = null) {
        this.changePasswordId = user ? user.id : null
        this.showDialogChangePassword = true
      },

      async clickDeleteItem (user) {
        this.deleteId = user.id
        this.showDialogDelete = true
      },

      async deleteItem () {
        try {
          this.loadingDelete = true

          await usersApi.delete(this.deleteId)

          this.showDialogDelete = false
          this.loadUsers()

          this.$snackbar.show({ color: 'success', message: this.$messages._('delete_success') })
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loadingDelete = false
          this.dialogDelete = false
        }
      },

    },

  }
</script>
